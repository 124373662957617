import { TableBase } from '../../tables/table_base'

class TachographFaults extends TableBase {
  constructor () {
    super('tachograph_faults')
  }

  paintTable ($faults, tachograph_faults) {
    $faults.empty()
    let noFaults = true;

    for (const id in tachograph_faults) {
      const row = tachograph_faults[id]

      const startTime = moment(row.fault_begin_time)
      const duration = row.fault_duration

      const $row = $('<tr>')
      const $vehicleInfoCell = $('<span>').attr({
        'data-tbl': 'veh',
        'data-id': row.veh_id
      }).addClass('hover-preview').text(row.reg)
      const $regCell = $('<td>').append($vehicleInfoCell)
      const $driverCell = $('<td>')
      if (row.codriver_name === null || row.codriver_name === '') {
        const $driverInfoCell = $('<span>').attr({
          'data-tbl': 'usr',
          'data-id': row.main_driver_id
        }).addClass('hover-preview').text(row.driver_name)
        $driverCell.append($driverInfoCell)
      } else {
        const $driverInfoCell1 = $('<span>').attr({
          'data-tbl': 'usr',
          'data-id': row.main_driver_id
        }).addClass('hover-preview').text(row.driver_name)
        const $driverInfoCell2 = $('<span>').attr({
          'data-tbl': 'usr',
          'data-id': row.sec_driver_id
        }).addClass('hover-preview').text(row.codriver_name)
        const spacer = document.createTextNode(' / ')

        $driverCell.append($driverInfoCell1, spacer, $driverInfoCell2)
      }

      const $timeCell = $('<td>').text(`${startTime.format('ddd Do MMM YYYY HH:mm')} (${duration})`)
      const $faultCell = $('<td>').text(row.fault_type)

      $row.append($regCell).append($driverCell).append($faultCell).append($timeCell)
      $faults.append($row)

      noFaults = false;
    }

    if (noFaults) {
      $faults.html('<tr><td colspan="4">No faults to display</td></tr>')
    }
  }
}

$(() => {
  if (app && app.DASHBOARD_CORE) {
    new TachographFaults()
  } else {
    console.warn('DashboardCore not initalized.')
  }
})
